const menuTrigger = document.getElementById('menu-trigger');
const menu = document.getElementById('menu');
const menuBG = document.querySelector('.faded');
const body = document.querySelector('body');
const erelijstLink = document.querySelector('.erelijst');

menuTrigger.addEventListener("click", function() {
    menu.classList.toggle('visible');
    menuBG.classList.toggle('visible');
    menuTrigger.classList.toggle('opened');
    body.classList.toggle('overflow-hidden-mobile');
    body.classList.toggle('menu-open');
});

if (erelijstLink) {
    erelijstLink.addEventListener('click', function() {
        menu.classList.remove('visible');
        menuBG.classList.remove('visible');
        menuTrigger.classList.remove('opened');
        body.classList.remove('overflow-hidden-mobile');
        body.classList.remove('menu-open');
    })
}

$(document).ready(function() {
    $('.iframe-popup').magnificPopup({
        type: 'iframe'
    });

    $('.image-popup').magnificPopup({
        type: 'image'
    });
});